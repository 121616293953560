import Vue from 'vue';
import Component from 'vue-class-component';
import * as ExcelJS from 'exceljs/dist/exceljs';
import * as FileSaver from 'file-saver';

import { QUESTIONS_STATUS } from '../enums';

@Component
export default class ExportQuestionsStatusReportXLSXMixin extends Vue {
  servertranslate = this.$options.filters?.servertranslate || (() => '');

  excelType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

  excelExtension = '.xlsx';

  get tableHeaders(): string[] {
    return [
      this.$t('tableHeaders.index') as string,
      this.$t('tableHeaders.courseName') as string,
      this.$t('tableHeaders.studyPlan') as string,
      this.$t('tableHeaders.incomplete') as string,
      this.$t('tableHeaders.pendingReview') as string,
      this.$t('tableHeaders.complete') as string,
      this.$t('tableHeaders.approved') as string,
      this.$t('tableHeaders.rejected') as string,
      // this.$t('tableHeaders.deleted') as string,
      `${this.$t('labels.NEED_TO_FIX')} ${this.$t('labels.TIME')}`,
      `${this.$t('labels.NEED_TO_FIX')} ${this.$t('labels.difficultyLevel')}`,
      this.$t('tableHeaders.questionsCount') as string,
    ];
  }

  exportXLSX(reportData, fromDate, toDate) {
    const workbook = this.createWorkbook();
    reportData.forEach((reportTable, tableIndex) => {
      this.addWorkSheet(workbook, reportTable, tableIndex, fromDate, toDate);
    });
    this.fireExportExcel(workbook, 'Questions_Status_Statistics_Report ');
  }

  createWorkbook() {
    const workbook = new ExcelJS.Workbook();
    workbook.views = [
      {
        x: 0,
        y: 0,
        width: 15000,
        height: 20000,
        firstSheet: 0,
        activeTab: 0,
        visibility: 'visible',
      },
    ];
    return workbook;
  }

  addWorkSheet(workbook, reportTable, tableIndex, fromDate, toDate) {
    const sheet = workbook.addWorksheet(
      `Table ${tableIndex + 1}`, {
        views: [{ showGridLines: false }], properties: { defaultColWidth: 12 },
      },
    );
    this.addHeaderToSheet(sheet, reportTable.tableScope, fromDate, toDate);
    this.setTableHeaders(sheet);
    this.drawTableRecords(sheet, reportTable);
  }

  addHeaderToSheet(sheet, tableScope, fromDate, toDate) {
    const tableTotalScope = tableScope.childsData.reduce((acc, childScope) => {
      acc.ar += ` - ${childScope.name.ar}`;
      acc.en += ` - ${childScope.name.en}`;
      return acc;
    }, tableScope.parentScopeData.name);
    const headerObjs = [
      {
        coords: ['B2', 'Z3'],
        value: this.servertranslate(tableTotalScope),
      },
    ];
    if (fromDate || toDate) {
      const dateString = `
        ${fromDate ? `${this.$t('labels.fromDate')}/ ${fromDate}   ` : ''}
        ${toDate ? `${this.$t('labels.toDate')}/ ${toDate}` : ''}
      `;
      headerObjs.push({
        coords: ['B4', 'Z5'],
        value: dateString,
      });
    } else { headerObjs[0].coords[1] = 'Z5'; }

    headerObjs.forEach((headerObj) => {
      const { coords, value } = headerObj;
      this.createStyledMergedCell(
        sheet, coords,
        {
          value,
          borders: false,
          bgColor: '202020',
          fontOptions: { color: { argb: 'ecebeb' }, size: 16, bold: true },
        },
      );
    });
  }

  setTableHeaders(sheet) {
    const headersCoordinates = [
      ['B6', 'B7'], ['C6', 'G7'], ['H6', 'I7'], ['J6', 'K7'], ['L6', 'M7'],
      ['N6', 'O7'], ['P6', 'Q7'], ['R6', 'S7'], ['T6', 'U7'], ['V6', 'X7'],
      ['Y6', 'Z7'],
    ];
    const tableHeaderBackGroundColor = '424242';
    this.tableHeaders.forEach((tHeader, headerIndex) => {
      this.createStyledMergedCell(sheet, headersCoordinates[headerIndex],
        {
          value: tHeader,
          bgColor: tableHeaderBackGroundColor,
          fontOptions: { color: { argb: 'acacac' }, size: 14, bold: true },
        });
    });
  }

  drawTableRecords(sheet, reportTable) {
    const tableCellsBackGroundColor = 'ecebeb';
    const columnsStartEndPair = [
      ['B', 'B'], ['C', 'G'], ['H', 'I'], ['J', 'K'], ['L', 'M'],
      ['N', 'O'], ['P', 'Q'], ['R', 'S'], ['T', 'U'], ['V', 'X'],
      ['Y', 'Z'],
    ];
    const formalizedRecords = reportTable.tableData.reduce((acc, courseData, courseIndex) => {
      acc.push([
        (courseIndex + 1),
        this.servertranslate(courseData.courseName),
        this.servertranslate(courseData.studyPlanName),
        courseData.questionsStatusData[QUESTIONS_STATUS.INCOMPLETE].questionsCount,
        courseData.questionsStatusData[QUESTIONS_STATUS.PENDING_REVIEW].questionsCount,
        courseData.questionsStatusData[QUESTIONS_STATUS.COMPLETE].questionsCount,
        courseData.questionsStatusData[QUESTIONS_STATUS.APPROVED].questionsCount,
        courseData.questionsStatusData[QUESTIONS_STATUS.REJECTED].questionsCount,
        // courseData.questionsStatusData[QUESTIONS_STATUS.DELETED].questionsCount,
        courseData.questionsStatusData.needToFixTime.questionsCount,
        courseData.questionsStatusData.needToFixDifficulty.questionsCount,
        courseData.totalQuestionsCount,
      ]);
      return acc;
    }, []);

    let rowStep = 8; // vertical step
    formalizedRecords.forEach((record, recordIndex) => {
      columnsStartEndPair.forEach((pair, pairIndex) => {
        this.createStyledMergedCell(sheet,
          [(pair[0] + (recordIndex + rowStep)), (pair[1] + (recordIndex + rowStep + 1))],
          {
            value: record[pairIndex],
            bgColor: tableCellsBackGroundColor,
            fontOptions: { color: { argb: '424242' }, size: 10 },
          });
      });
      rowStep += 1;
    });

    this.drawTableFooter(
      sheet, ((2 * rowStep) - 8), columnsStartEndPair.slice(3), reportTable.tableTotals,
    );
  }

  drawTableFooter(sheet, startPos, columnsStartEndPair, tableTotals) {
    const formalizedTableTotals = [
      tableTotals.questionsStatusTotalsData[QUESTIONS_STATUS.INCOMPLETE].questionsCount,
      tableTotals.questionsStatusTotalsData[QUESTIONS_STATUS.PENDING_REVIEW].questionsCount,
      tableTotals.questionsStatusTotalsData[QUESTIONS_STATUS.COMPLETE].questionsCount,
      tableTotals.questionsStatusTotalsData[QUESTIONS_STATUS.APPROVED].questionsCount,
      tableTotals.questionsStatusTotalsData[QUESTIONS_STATUS.REJECTED].questionsCount,
      // tableTotals.questionsStatusTotalsData[QUESTIONS_STATUS.DELETED].questionsCount,
      tableTotals.totalNeedToFixTime,
      tableTotals.totalNeedToFixDifficulty,
      tableTotals.totalQuestionsCount,
    ];
    this.createStyledMergedCell(sheet,
      [`B${startPos}`, `I${(startPos + 1)}`],
      {
        value: this.$t('tableHeaders.questionsCount'),
        bgColor: '424242',
        fontOptions: { color: { argb: 'acacac' }, size: 14, bold: true },
      });
    columnsStartEndPair.forEach((pair, pairIndex) => {
      this.createStyledMergedCell(sheet,
        [(pair[0] + startPos), (pair[1] + (startPos + 1))],
        {
          value: formalizedTableTotals[pairIndex],
          bgColor: 'ecebeb',
          fontOptions: { color: { argb: '424242' }, size: 14 },
        });
    });
  }

  createStyledMergedCell(sheet, [topLeft, bottomRight]: string[], {
    value, bgColor, fontOptions, borders = true,
  }) {
    sheet.mergeCells(topLeft, bottomRight);
    const cell = sheet.getCell(topLeft);
    cell.value = value;
    cell.alignment = {
      vertical: 'middle',
      horizontal: 'center',
    };
    if (bgColor) {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: bgColor },
      };
    }
    if (fontOptions) {
      cell.font = fontOptions;
    }
    if (borders) {
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
    }
    return cell;
  }

  fireExportExcel(workbook, fileName) {
    workbook.xlsx.writeBuffer().then((excelBuffer) => {
      this.saveAsExcelFile(excelBuffer, fileName);
    });
  }

  saveAsExcelFile(buffer, fileName) {
    const data: Blob = new Blob([buffer], { type: this.excelType });
    FileSaver.saveAs(data, `${fileName
    }${new Date().getDate()}-${new Date().getMonth() + 1}-${new Date().getFullYear()}${
      this.excelExtension}`);
  }
}
